<template>
    <div class="">
        <div class="pb-4 pt-2 border-b border-gray-300 flex items-center justify-center">
            <DTabs type="pill" @selected="onSelected" :padding="false">
                <DTab name="Month" active>
                    <Teleport to="#event-list">
                        <div class="py-1">
                            <DDatepicker
                                v-model="selectedDate"
                                :events="eventIndicators"
                                minDate="1970-01-01"
                                maxDate="2037-01-01"
                                inline
                                fullwidth
                                @change-month="setMonth"
                                :display-dropdowns="false"
                                :locale="schoolLocale"
                                @update:modelValue="updateSelectedDate"
                            />
                        </div>

                        <div class="border-t border-gray-300">
                            <div v-if="selectedEvents.length > 0 && !fetching" class="pb-[var(--beyond-safe-area-inset-bottom)]">
                                <div v-for="(eventGroup, key) in selectedEventsWithHeadings" :key="key" class="py-4">
                                    <p class="text-gray-800 py-4 border-b border-gray-200 px-4">
                                        {{key}}
                                    </p>

                                    <ListItem
                                        v-for="(event, index) in eventGroup"
                                        :config="event"
                                        :key="index"
                                        line
                                    />
                                </div>
                            </div>

                            <Empty v-else-if="!fetching" class="py-10">
                                <template v-slot:title>No events available.</template>
                                <template v-slot:subtitle>Please check another day or come back later.</template>
                            </Empty>

                            <div v-else class="flex justify-center py-12">
                                <UILoading class="w-12 h-12" />
                            </div>
                        </div>
                    </Teleport>
                </DTab>

                <DTab name="Week">
                    <Teleport to="#event-list">
                        <div class="p-2 py-3 flex items-center justify-between">
                            <DButton
                                theme="transparent"
                                @click="decreaseDateBy('week')"
                                icon="chevron-left"
                            />

                            <span class="text-gray-900 font-medium">
                                {{weekDisplay}}
                            </span>

                            <DButton
                                theme="transparent"
                                @click="increaseDateBy('week')"
                                icon="chevron-right"
                            />
                        </div>
                        <div>
                            <div v-if="eventItems.length > 0 && !fetching" class="pb-[var(--beyond-safe-area-inset-bottom)]">
                                <div v-for="(eventGroup, key) in eventsWithHeadings" :key="key" class="py-2">
                                    <p class="text-gray-800 py-4 border-b border-gray-200 px-4">
                                        {{key}}
                                    </p>

                                    <ListItem
                                        v-for="(event, index) in eventGroup"
                                        :config="event"
                                        :key="index"
                                        line
                                    />
                                </div>
                            </div>

                            <Empty v-else-if="!fetching" class="py-10">
                                <template v-slot:title>No events available.</template>
                                <template v-slot:subtitle>Please check another week or come back later.</template>
                            </Empty>

                            <div v-else class="flex justify-center py-12">
                                <UILoading class="w-12 h-12" />
                            </div>
                        </div>
                    </Teleport>
                </DTab>

                <DTab name="Day">
                    <Teleport to="#event-list">
                        <div class="p-3 flex items-center justify-between">
                            <DButton
                                theme="transparent"
                                @click="decreaseDateBy('day')"
                                icon="chevron-left"
                            />

                            <span class="text-gray-900 font-medium">
                                <span :class="{ 'text-[--color-700] font-semibold': dayNameDisplay === 'Today' }">{{dayNameDisplay}}</span>, {{dayDisplay}}
                            </span>

                            <DButton
                                theme="transparent"
                                @click="increaseDateBy('day')"
                                icon="chevron-right"
                            />
                        </div>

                        <div>
                            <div v-if="eventItems.length > 0 && !fetching" class="pb-[var(--beyond-safe-area-inset-bottom)]">
                                <ListItem
                                    v-for="(event, index) in eventItems"
                                    :config="event"
                                    :key="index"
                                    line
                                />
                            </div>

                            <Empty v-else-if="!fetching" class="py-10">
                                <template v-slot:title>No events available.</template>
                                <template v-slot:subtitle>Please check another day or come back later.</template>
                            </Empty>

                            <div v-else class="flex justify-center py-12">
                                <UILoading class="w-12 h-12" />
                            </div>
                        </div>
                    </Teleport>
                </DTab>
            </DTabs>
        </div>

        <div id="event-list" />

        <template v-if="headerMenuElement">
            <Teleport v-if="filters.length" :to="headerMenuElement" filter="">
                <FiltersButton :uuid="filterUuid" :filters="config.meta.filters" />
            </Teleport>

            <Teleport v-if="hasModalOptions" :to="headerMenuElement">
                <DButton id="open-modal" theme="transparent" icon="ellipsis-vertical" />
            </Teleport>

            <IonModal ref="modal" trigger="open-modal" :initial-breakpoint="1" :breakpoints="[0, 0.25, 0.5, 0.75, 1]" class="ds-modal-bottom-sheet">
                <div class="py-6">
                    <div class="pb-[var(--beyond-safe-area-inset-bottom)]">
                        <UISimple
                            title="Subscribe to calendar"
                            :icon="{ icon: 'calendar-plus', icon_url: null, color: null }"
                            :right-icon="{ icon: 'arrow-square-out', icon_url: null, color: null }"
                            :border="false"
                            @click="subscribeCalendar"
                        />
                    </div>
                </div>
            </IonModal>
        </template>
    </div>
</template>

<script setup>
    import {
        DTabs, DTab, DButton, DDatepicker,
    } from '@digistorm/spark'
    import { keyBy, keys } from 'lodash'
    import { isCancel } from 'axios'
    import dayjs from '@/dayjs'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'
    import FiltersButton from '@/components/filters/FiltersButton.vue'
    import { useFilterStore } from '@/stores'
    import { useFilters } from '@/composables'

    const props = defineProps({
        config: Object,
    })

    const moduleHelpers = useModuleHelpers()
    const moduleStore = useModuleStore()
    const versionStore = useVersionStore()
    const filterStore = useFilterStore()

    const headerMenu = inject('headerMenu', null)
    const { version } = storeToRefs(versionStore)
    const { fetching } = storeToRefs(moduleStore)
    const { applyLocalFilter } = useFilters()

    const filterUuid = ref(null)

    const TAB_MONTH = 0
    const TAB_WEEK = 1
    const TAB_DAY = 2

    const now = dayjs()
    const selectedDate = ref(dayjs().format('YYYY-MM-DD'))
    const previouslySelectedMonth = ref(dayjs().month())
    let selectedView = TAB_MONTH
    let rangeStart = dayjs().startOf('month').startOf('week')
    let rangeEnd = dayjs().endOf('month').endOf('week')

    const filters = computed(() => props.config.meta?.filters ?? [])
    const filtersById = computed(() => keyBy(filters.value, 'id'))

    const schoolLocale = computed(() => (version.value.school_locale ?? '').replace('_', '-'))
    const isUSLocale = computed(() => schoolLocale.value === 'en-US')

    const activeFilters = computed(() => {
        return filterStore.filtersForUuid(filterUuid.value)
    })

    const filtering = computed(() => {
        return keys(activeFilters.value).length > 0
    })

    const eventItems = computed(() => {
        // Generate a curated list of events
        return chain(props.config.data.events ?? [])
            .filter((event) => {
                // Only show events which overlap the selected time period
                const inRange = dayjs(event.end).isSameOrAfter(rangeStart, 'day')
                    && dayjs(event.start).isSameOrBefore(rangeEnd, 'day')

                if (!inRange) {
                    return false
                }

                // Don't filter if no filters are selected
                if (!filtering.value) {
                    return true
                }

                // Check if event tags matches filters
                return applyLocalFilter(filtersById.value, activeFilters.value, event.tags)
            })
            .flatMap((event) => {
                // Start at the first day of event, or start of range
                const start = dayjs(event.start)
                let day = dayjs.max(start, rangeStart).startOf('day')
                // End at the last day of event, or end of range
                const end = dayjs.min(dayjs(event.end), rangeEnd)
                const eventItems = []

                // Loop through each day of the event
                while (day.isSameOrBefore(end)) {
                    const isCurrentYear = day.isSame(now, 'year')
                    let headingDateFormat = isUSLocale.value ? 'dddd, MMMM D' : 'dddd, D MMMM'
                    if (!isCurrentYear) {
                        headingDateFormat += ' YYYY'
                    }
                    eventItems.push({
                        date: day.format('YYYY-MM-DD'),
                        // Sort by initial date for multi-day events
                        timestamp: start.unix(),
                        heading: day.format(headingDateFormat),
                        meta: { id: event.id, read: event.read_url },
                        data: event,
                        link: event.link,
                    })
                    day = day.add(1, 'day')
                }
                return eventItems
            })
            .sortBy('timestamp')
            .value()
    })

    const eventsWithHeadings = computed(() => {
        return groupBy(eventItems.value, 'heading')
    })

    const selectedEvents = computed(() => {
        return filter(eventItems.value, (event) => {
            return event.date === dayjs(selectedDate.value).format('YYYY-MM-DD')
        })
    })

    const selectedEventsWithHeadings = computed(() => {
        return groupBy(selectedEvents.value, 'heading')
    })

    const eventIndicators = computed(() => {
        return map(eventItems.value, 'date')
    })

    const dayFormat = computed(() => {
        let format = isUSLocale.value ? 'MMM D' : 'D MMM'
        if (!dayjs().isSame(selectedDate.value, 'year')) {
            format += ' YYYY'
        }
        return format
    })

    const weekDisplay = computed(() => {
        return `Week of ${dayjs(selectedDate.value).format(dayFormat.value)}`
    })

    const dayNameDisplay = computed(() => {
        if (dayjs(selectedDate.value).isToday()) {
            return 'Today'
        }
        return dayjs(selectedDate.value).format('dddd')
    })

    const dayDisplay = computed(() => {
        return dayjs(selectedDate.value).format(dayFormat.value)
    })

    const subscribeUrl = computed(() => {
        return props.config.meta.subscribe_url
    })

    const hasModalOptions = computed(() => {
        return !!subscribeUrl.value
    })

    const headerMenuElement = computed(() => {
        return headerMenu?.value?.$el?.querySelector('#header-menu')
    })

    const updateEventParams = (start, end) => {
        if (selectedView === TAB_WEEK) {
            start = start.startOf('week')
            end = start.endOf('week')
        } else if (selectedView === TAB_DAY) {
            start = start.startOf('day')
            end = start.endOf('day')
        }

        rangeStart = start
        rangeEnd = end

        moduleStore.setQueryParams({
            start: start.toISOString(),
            end: end.toISOString(),
        })

        moduleStore.loadModule()
            .catch((error) => {
                if (!isCancel(error)) {
                    throw error
                }
            })
    }

    const setMonth = ({ month, year }) => {
        const start = dayjs().set('month', month).set('year', year).startOf('month')
        const end = dayjs(start).endOf('month').endOf('week')

        // stay on the same selected date if selected is same month as start
        if (start.month() !== dayjs(selectedDate.value).month()) {
            if (start.month() === dayjs().month() && start.year() === dayjs().year()) {
                // go to today if current month and year
                selectedDate.value = dayjs().format('YYYY-MM-DD')
            } else {
                selectedDate.value = start.format('YYYY-MM-DD')
            }
        }

        updateEventParams(start.startOf('week'), end)
    }

    const increaseDateBy = (amount) => {
        const start = dayjs(selectedDate.value).add(1, amount)
        const end = dayjs(start).endOf(amount)

        selectedDate.value = start.format('YYYY-MM-DD')

        updateEventParams(start, end)
    }

    const decreaseDateBy = (amount) => {
        const start = dayjs(selectedDate.value).subtract(1, amount)
        const end = dayjs(start).endOf(amount)

        selectedDate.value = start.format('YYYY-MM-DD')

        updateEventParams(start, end)
    }

    const onSelected = (index) => {
        const start = dayjs(selectedDate.value)
        const end = null
        selectedView = index

        if (index === TAB_MONTH) {
            setMonth({
                month: start.month(),
                year: start.year(),
            })
            return
        }

        updateEventParams(start, end)
    }

    const updateSelectedDate = (newValue) => {
        const newMonth = dayjs(newValue).month()
        if (newMonth !== previouslySelectedMonth.value && selectedView === TAB_MONTH) {
            previouslySelectedMonth.value = newMonth
            setMonth({
                month: newMonth,
                year: dayjs(newValue).year(),
            })
        }
    }

    const subscribeCalendar = () => {
        window.location.href = subscribeUrl.value
    }

    const handleChildRead = (id) => {
        each(props.config.data.events, (event) => {
            if (event.id === id) {
                event.read = true
            }
        })
    }

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
        events.on('read', handleChildRead)

        // If events aren't provided with the calendar then load them in
        if (props.config.data.events === null) {
            updateEventParams(rangeStart, rangeEnd)
        }

        filterStore.initialiseFilters(filters.value)
            .then((uuid) => {
                filterUuid.value = uuid
            })
    })

    onUnmounted(() => {
        if (filterUuid.value) {
            filterStore.clearFilters(filterUuid.value)
        }
    })
</script>
