<template>
    <div
        class="px-4 mb-8"
        :class="{
            'border border-gray-300 rounded-lg mx-4 my-4 p-4': border,
        }"
    >
        <div v-if="title" class="text-gray-600 text-[13px] uppercase tracking-wider mb-3">
            {{title}}
        </div>
        <StudentSelector v-if="students" :students="students" @change="onStudentsChange" :errors="errors" />
        <SchemaForm
            v-model="entry"
            :schema="schema"
            :schema-version="config.data.schema_version"
            :form-errors="errors"
        />
        <div v-if="buttons" class="mt-8 flex flex-wrap gap-1">
            <DButton
                v-for="(button, index) in buttons"
                :key="`button-${index}`"
                :theme="button.type"
                :wide="button.flex"
                :loading="(submitting && submittingIndex === index) || fetchingSchema"
                :disabled="submitting"
                :right-icon="button.icon"
                :left-icon="button.left_icon"
                icon-set="ph"
                @click="handleButton(button, index)"
            >
                {{ button.title }}
            </DButton>
        </div>
        <div v-else class="mt-6 flex flex-row-reverse">
            <DButton @click="handleButton()" :loading="submitting || fetchingSchema">
                {{ config.data.button_text || 'Submit' }}
            </DButton>
        </div>
    </div>
</template>

<script setup>
    import { DButton } from '@digistorm/spark'
    import { SchemaForm } from '@digistorm/forms'
    import { debounce, isEqual } from 'lodash'
    import StudentSelector from '@/components/ui/Form/StudentSelector.vue'

    import linkType from '@/constants/linkType'
    import { useModuleStore } from '@/stores'

    const moduleHelpers = useModuleHelpers()
    const { snackbar } = useSnackbars()
    const moduleStore = useModuleStore()

    const props = defineProps({
        config: Object,
    })

    const schema = ref(props.config.data.schema)
    const buttons = ref(props.config.data.buttons)
    const students = ref(props.config.data.students)
    const title = ref(props.config.data.title)
    const border = ref(props.config.data.border)
    const schemaUpdateUrl = ref(props.config.data.schema_update_url)
    const selectedStudentIds = ref([])

    const entry = ref({})
    const errors = ref({})
    const submitting = ref(false)
    const submittingIndex = ref(null)
    const fetchingSchema = ref(false)

    provide('formErrors', errors)

    const schemaUpdateParams = computed(() => {
        const params = {
            students: selectedStudentIds.value,
        }
        const paramConfig = props.config.data.schema_update_params ?? []
        paramConfig.forEach((param) => {
            params[param.key] = param.value || entry.value[param.entry_value_path]
        })
        return params
    })

    const onStudentsChange = (selectedStudents) => {
        selectedStudentIds.value = selectedStudents
    }

    const handleButton = (button = null, index = null) => {
        if (!button) {
            // Buttons weren't provided, use default url
            button = {
                link: {
                    type: linkType.apiAction,
                    url: props.config.data.url,
                },
            }
        }

        submitting.value = true
        submittingIndex.value = index

        // Set the payload on the link and include the selected students
        if (students.value) {
            button.link.payload = assign(entry.value, { students: selectedStudentIds.value })
        } else {
            button.link.payload = entry.value
        }

        return moduleHelpers.handleClick(button, null, false)
            .catch((error) => {
                if (error?.response?.status === 422) {
                    errors.value = error.response.data?.errors
                    return
                }

                console.error('error', error)
                snackbar({
                    message: 'Error submitting form',
                    type: 'error',
                })
            })
            .finally(() => {
                submitting.value = false
                submittingIndex.value = null
            })
    }

    const debounceLoadSchema = debounce(() => {
        fetchingSchema.value = true
        moduleStore.loadContent(schemaUpdateUrl.value, schemaUpdateParams.value)
            .then(({ data }) => {
                schema.value = data.data.schema
            })
            .finally(() => {
                fetchingSchema.value = false
            })
    }, 600)

    // For any schemaUpdateParams, watch for entry changes if used in query params
    watch(schemaUpdateParams, (value, oldValue) => {
        if (!isEqual(value, oldValue)) {
            debounceLoadSchema()
        }
    })

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })
</script>
