<template>
    <div class="flex flex-col items-start pt-10 px-8 w-full min-h-full flex-grow">
        <div class="w-full">
            <div class="mb-10 w-full">
                <h2 class="text-3xl font-bold mb-4">{{ config.layout.title }}</h2>
                <p class="text-gray-600">{{ config.layout.description }}</p>
            </div>
            <div class="w-full">
                <div
                    v-for="(notification, index) in notifications"
                    :key="index"
                    class=" p-4 bg-white shadow-md rounded-lg border border-gray-200 mb-2"
                    :class="notification.opacityClass"
                >
                    <div class="flex items-start">
                        <div class="flex-shrink-0 flex items-center justify-center w-5 h-5 rounded-full mt-0.5">
                            <PhIcon class="text-[--color-500] mr-2 shrink-0" icon="megaphone" weight="fill" :size="16" />
                        </div>
                        <div class="flex-1 ml-1">
                            <div class="flex justify-between items-center">
                                <span class="font-medium text-gray-900">{{notification.title}}</span>
                                <span class="text-gray-500 text-sm">{{notification.time}}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="mt-2 w-full">
                            <div class="bg-gray-300 h-3 rounded-[4px]  mb-2" :class="notification.lineOneClass" />
                            <div class="bg-gray-300 h-3 rounded-[4px] " :class="notification.lineTwoClass" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Teleport :to="footerMenu?.$el">
            <div class="px-8 pb-6 flex flex-col bg-[var(--background)] w-full mt-auto" data-cy="tour-footer">
                <DButton class="mb-1" @click="onNotificationAction" wide>
                    {{ config.action_button_text }}
                </DButton>
                <DButton class="mb-1" @click="onSkipAction" theme="transparent" wide>
                    {{ config.skip_step_button_text }}
                </DButton>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
    import { DButton } from '@digistorm/spark'
    import PhIcon from '@/components/app/PhIcon.vue'
    import { useTourStore } from '@/stores/tour'

    const emit = defineEmits(['action-skipped', 'action-completed'])

    const pushNotifications = usePushNotifications()
    const tourStore = useTourStore()

    const props = defineProps({
        config: Object,
    })

    const footerMenu = inject('footerMenu', null)

    const notifications = [
        {
            title: 'Bus route cancellations',
            time: 'Now',
            lineOneClass: 'w-1/2',
            lineTwoClass: 'w-2/3',
            opacityClass: '',
        },
        {
            title: 'South carpark is closed',
            time: '1h',
            lineOneClass: 'w-3/5',
            lineTwoClass: 'w-2/5',
            opacityClass: 'opacity-60',
        },
        {
            title: 'Temporary library closure',
            time: '2d',
            lineOneClass: 'w-5/6',
            lineTwoClass: 'w-2/5',
            opacityClass: 'opacity-30',
        },
        {
            title: 'North carpark is closed',
            time: '3d',
            lineOneClass: 'w-1/2',
            lineTwoClass: 'w-3/4',
            opacityClass: 'opacity-10',
        },
    ]

    const onNotificationAction = async () => {
        tourStore.handleAction(props.config.action_type, false)
        await pushNotifications.register()
        emit('action-completed', true)
    }

    const onSkipAction = () => {
        tourStore.handleAction(props.config.action_type, true)
        emit('action-skipped')
    }
</script>
