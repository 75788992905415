<template>
    <component
        :is="listTypes[type]"
        :notification="content.read !== null"
        :read="hasRead"
        :readBackground="content.read_background"
        :image="content.image"
        :image-type="content.image_type"
        :is-video="content.is_video"
        :line="content.line"
        :line-clamp="content.line_clamp"
        :icon="content.icon"
        :badges="content.badges"
        :unread-count="badgeCount"
        :right-icon="rightIcon"
        :image-url="content.image_url"
        :avatar="content.avatar"
        :has-link="hasLink"
        :expands="content.expands"
        :border="content.border"
        :footer-avatar="content.footer_avatar"
        @click="onClick"
    >
        <template v-if="content.title" v-slot:title>
            <UIHtml :html="content.title" class="prose leading-6 text-[length:inherit] text-[color:inherit]" style="overflow-wrap: anywhere;" />
        </template>
        <template v-if="content.subheading" v-slot:subheading>
            <UIHtml :html="content.subheading" class="prose leading-5 text-[length:inherit] text-[color:inherit]" style="overflow-wrap: anywhere;" />
        </template>
        <template v-if="content.body" v-slot:body>
            <UIHtml :html="content.body" class="prose leading-5 text-[length:inherit] text-[color:inherit]" style="overflow-wrap: anywhere;" />
        </template>
        <template v-if="content.subtitle" v-slot:subtitle>
            <UIHtml :html="content.subtitle" class="prose text-[length:inherit] text-[color:inherit]" style="overflow-wrap: anywhere;" />
        </template>
        <template v-if="content.right_text" v-slot:indicators>
            <UIHtml :html="content.right_text" class="inline prose text-[length:inherit] text-[color:inherit]" />
        </template>
    </component>
</template>

<script setup>
    import { forEach, intersection } from 'lodash'
    import linkType from '@/constants/linkType'

    import UIList from '@/components/ui/ListItemLayouts/UIList.vue'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'
    import UIGallery from '@/components/ui/ListItemLayouts/UIGallery.vue'
    import UIHtml from '@/components/ui/UIHtml.vue'
    import { disableAutomaticRightIconKey } from '@/keys'

    const disableAutomaticRightIcon = inject(disableAutomaticRightIconKey, false)

    const listTypes = {
        list: UIList,
        simple: UISimple,
        gallery: UIGallery,
    }

    const props = defineProps({
        config: Object,
        type: {
            type: String,
            default: 'list',
        },
    })

    const moduleStore = useModuleStore()
    const moduleHelpers = useModuleHelpers()

    const clicking = ref(false)

    const meta = computed(() => props.config.meta)
    const content = computed(() => props.config.data)

    // TODO: Improve check so that the component type is considered
    const getChildIds = (items) => {
        let ids = []
        forEach(items, (item) => {
            if (item.meta) {
                ids.push(item.meta.id)
            }
            if (item.link?.component?.items) {
                ids = ids.concat(getChildIds(item.link.component.items))
            }
        })
        return ids
    }

    const childIds = computed(() => {
        return getChildIds(get(props.config, 'link.component.items', []))
    })

    const hasLink = computed(() => {
        return !!get(props.config, 'link')
    })

    const isModal = computed(() => {
        return props.config.link.type === 'modal'
    })

    const hasLinkExternal = computed(() => {
        return [
            linkType.external,
            linkType.externalApp,
        ].includes(get(props.config, 'link.type'))
    })

    const hasLinkAction = computed(() => {
        return [
            linkType.apiAction,
        ].includes(get(props.config, 'link.type'))
    })

    const hasRead = computed(() => {
        return content.value.read || moduleStore.currentModulesReadItemIds.includes(meta.value.id)
    })

    const badgeCount = computed(() => {
        return content.value.unread_count - intersection(childIds.value, moduleStore.currentModulesReadItemIds).length
    })

    const rightIcon = computed(() => {
        if (content.value.right_icon) {
            return content.value.right_icon
        }

        if (!hasLink.value || hasLinkAction.value || disableAutomaticRightIcon || isModal.value) {
            return null
        }

        if (hasLinkExternal.value) {
            return {
                icon: 'arrow-square-out',
                icon_url: null,
                color: null,
            }
        }

        return {
            icon: 'caret-right',
            icon_url: null,
            color: null,
        }
    })

    const onClick = () => {
        if (clicking.value) {
            return
        }

        clicking.value = true
        moduleHelpers.handleClick(props.config, get(meta, ['value', 'id']))
            .catch((e) => {
                console.log('Failed to open link', e)
            })
            .finally(() => {
                clicking.value = false
            })
    }
</script>
