<template>
    <div class="flex flex-col items-start pt-10 px-8 w-full min-h-full flex-grow">
        <div class="w-full">
            <div class="mb-10 w-full">
                <h2 class="text-3xl font-bold mb-4">{{ config.layout.title }}</h2>
                <p class="text-gray-600">{{ config.layout.description }}</p>
            </div>
            <div class="w-full">
                <DButton
                    theme="secondary"
                    @click="toggleAllOptions"
                    data-cy="tour-subscription-select-all-button"
                    wide
                >
                    <template v-if="allCategoriesSelected">Deselect all ({{config.layout.categories.length}})</template>
                    <template v-else>Select all ({{config.layout.categories.length}})</template>
                </DButton>
                <div class="flex flex-col">
                    <UISimple
                        v-for="option in config.layout.categories"
                        :key="option.value"
                        :title="option.title"
                        @click="toggleOption(option)"
                    >
                        <template v-slot:right-content>
                            <DSwitch class="pointer-events-none" :model-value="option.active" />
                        </template>
                    </UISimple>
                </div>
            </div>
        </div>
        <Teleport :to="footerMenu?.$el">
            <div class="px-8 pb-6 flex flex-col bg-[var(--background)] w-full mt-auto" data-cy="tour-footer">
                <DButton class="mb-1" @click="complete" :disabled="oneOrMoreCategoriesSelected" wide>
                    {{ config.action_button_text }}
                </DButton>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
    import { every } from 'lodash'

    import { DSwitch, DButton } from '@digistorm/spark'
    import { useTourStore } from '@/stores/tour'

    const emit = defineEmits(['action-completed'])

    const props = defineProps({
        config: Object,
    })

    const footerMenu = inject('footerMenu', null)

    const tourStore = useTourStore()

    const oneOrMoreCategoriesSelected = computed(() => {
        return !some(props.config.layout.categories, { active: true })
    })

    const allCategoriesSelected = computed(() => {
        return every(props.config.layout.categories, { active: true })
    })

    const toggleOption = (option, value) => {
        option.active = value ?? !option.active
    }

    const toggleAllOptions = () => {
        if (allCategoriesSelected.value) {
            each(props.config.layout.categories, (option) => {
                toggleOption(option, false)
            })
        } else {
            each(props.config.layout.categories, (option) => {
                toggleOption(option, true)
            })
        }
    }

    const complete = () => {
        tourStore.selectedCategories = props.config.layout.categories.filter((item) => item.active).map((item) => item.value)
        emit('action-completed')
    }
</script>
