<template>
    <div class="pt-6 h-[500px] overflow-y-scroll flex flex-col">
        <template v-if="editingNote || noteOnly">
            <div class="flex px-4 border-b pb-4">
                <div class="w-10">
                    <template v-if="!noteOnly">
                        <PhIcon v-if="isIos" @click="exitEdit" icon="caret-left" weight="regular" :size="18" />
                        <PhIcon v-else @click="exitEdit" icon="arrow-left" weight="regular" :size="18" />
                    </template>
                </div>
                <div class="mx-auto">{{ editNoteTitle }}</div>
                <div class="w-10 text-ux-link font-medium text-sm" @click="saveEdit">Save</div>
            </div>
            <div class="text-gray-900 font-medium flex px-4 py-4">
                {{ item ? item.title : '' }}
                <div @click="deleteEdit()" class="flex text-red-700 ml-auto text-sm" v-if="editedText.length">
                    <PhIcon
                        icon="trash"
                        weight="regular"
                        :size="18"
                        class="mr-1 ml-2"
                    />
                    Delete note
                </div>
            </div>
            <DInput
                v-model="editedText"
                tag="textarea"
                placeholder="Add a note..."
                theme="transparent"
                :rows="4"
                ref="commentInput"
                class="px-2"
            />
        </template>
        <template v-else>
            <template v-if="item">
                <div class="border mx-4 py-2 px-4 bg-gray-100 rounded">
                    <div class="text-gray-900 font-medium">
                        {{ item.title }}
                    </div>
                    <div class="text-gray-600 text-sm flex items-center">
                        {{ item.status_text }}
                        <UIIcon
                            v-for="icon in item.icons"
                            :key="`item-${item.id}-icon-${icon}`"
                            class="text-primary-700 ml-2"
                            :icon="icon"
                            weight="bold"
                            :size="16"
                        />
                    </div>
                </div>
                <template v-if="noteText">
                    <div @click="editNote(item)" class="flex items-start mt-2 px-4 py-2 text-sm text-gray-600">
                        <div class="text-neutral-base">
                            {{ noteText }}
                        </div>
                        <div v-if="!item?.disabled" class="flex text-black ml-auto text-sm">
                            <PhIcon
                                icon="pencil-simple"
                                weight="regular"
                                :size="18"
                                class="mr-1 ml-2"
                            />
                            Edit
                        </div>
                    </div>
                    <div v-if="!item?.disabled" @click="deleteNote(item)" class="flex text-red-700 ml-2 mt-3 text-sm">
                        <PhIcon
                            icon="trash"
                            weight="regular"
                            :size="18"
                            class="mr-1 ml-2"
                        />
                        Delete note
                    </div>
                </template>
                <div v-else-if="!item?.disabled" @click="addNote(item)" class="flex text-black ml-2 mt-6 text-sm">
                    <PhIcon
                        icon="pencil-simple"
                        weight="regular"
                        :size="18"
                        class="mr-1 ml-2"
                    />
                    Add note
                </div>
            </template>
            <template v-if="!item?.disabled">
                <div
                    class="font-medium px-4 py-2 transition-colors duration-150"
                    :class="{
                        'text-lg': !item,
                        'text-sm border-t mt-6 pt-4': item,
                    }"
                >
                    {{ title }}
                </div>
                <IonContent ref="contentScroll" class="flex-1">
                    <div
                        v-for="action in actions"
                        :key="action.value"
                        class="px-4 my-4 flex items-center"
                        @click="onAction(action)"
                    >
                        <UIIcon
                            :icon="action.icon"
                            weight="bold"
                            :size="16"
                            class="mr-2"
                        />
                        {{ action.title }}
                    </div>
                </IonContent>
            </template>
        </template>
    </div>
</template>
<script setup>
    import { DInput } from '@digistorm/spark'
    import UIIcon from '@/components/ui/UIIcon.vue'
    import PhIcon from '@/components/app/PhIcon.vue'

    const props = defineProps({
        title: String,
        actions: Array,
        item: Object,
        noteOnly: Boolean,
        onAction: Function,
        onNote: Function,
        onEditingNote: Function,
    })

    const deviceStore = useDeviceStore()

    const { isIos } = storeToRefs(deviceStore)

    const noteText = ref(props.item?.note || '')
    const editedText = ref(props.item?.note || '')
    const editingNote = ref(false)
    const addingNewNote = ref(props.noteOnly)

    const editNoteTitle = computed(() => {
        return addingNewNote.value ? 'Add note' : 'Edit note'
    })

    const addNote = () => {
        editingNote.value = true
        addingNewNote.value = true
    }

    const editNote = () => {
        if (props.item?.disabled) {
            return
        }
        editingNote.value = true
        addingNewNote.value = false
    }

    const exitEdit = () => {
        editingNote.value = false
    }

    const deleteEdit = () => {
        editedText.value = ''
    }

    const saveEdit = () => {
        if (props.noteOnly) {
            props.onNote(editedText.value)
        }
        noteText.value = editedText.value
        editingNote.value = false
    }

    const deleteNote = () => {
        noteText.value = ''
        editedText.value = ''
    }

    watch(noteText, (newVal) => {
        props.onNote(newVal)
    })

    watch(editingNote, (newVal) => {
        props.onEditingNote?.(newVal)
    })
</script>
