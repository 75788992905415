<template>
    <div class="flex flex-col h-full my-4">
        <div class="flex flex-col items-center border m-6 rounded-lg shadow-lg px-6 py-10">
            <div v-if="loadedUserImage" class="flex justify-center mb-4">
                <div :class="userImageClass" class="rounded-lg bg-cover bg-center" :style="{ backgroundImage: `url(${loadedUserImage})` }" />
            </div>

            <div class="mb-6">
                <div v-if="name" class="text-lg font-bold text-center mb-2">
                    {{ name }}
                </div>
                <div v-if="yearLevel" class="text-sm text-gray-600 text-center mb-2">
                    {{ yearLevel }}
                </div>
                <div v-if="positionTitle" class="text-sm text-gray-600 text-center mb-2">
                    {{ positionTitle }}
                </div>
            </div>

            <img
                v-if="barcodeImageUrl"
                :class="barcodeClass"
                :src="barcodeImageUrl"
                class="mb-4"
                alt="barcode"
            />

            <div class="w-full">
                <div class="flex items-center">
                    <p :class="textClass" class="text-sm font-medium mr-2">{{ userId }}</p>
                    <div v-if="hasRoles" class="flex flex-wrap items-center ml-auto">
                        <DBadge
                            class="mr-1 capitalize"
                            v-for="(badge, index) in roles"
                            :key="`badge-${index}`"
                        >
                            {{ badge }}
                        </DBadge>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full flex justify-center my-4 flex-grow flex-col">
            <img :src="version.logo_file.url" alt="logo" class="h-16 object-contain" />
        </div>
    </div>
</template>

<script setup>
    import { DBadge } from '@digistorm/spark'

    const moduleHelpers = useModuleHelpers()
    const versionStore = useVersionStore()
    const { version } = storeToRefs(versionStore)

    const props = defineProps({
        config: Object,
    })

    const loadedUserImage = ref(null)
    const name = ref(props.config.data.name)
    const yearLevel = ref(props.config.data.year_level)
    const positionTitle = ref(props.config.data.position_title)
    const barcodeImageUrl = ref(props.config.data.barcode_image_url)
    const userId = ref(props.config.data.id)
    const roles = ref(props.config.data.roles)

    const hasRoles = computed(() => !isEmpty(roles.value))

    const barcodeClass = computed(() => ({
        'max-h-[124px]': props.config.data.type === 'qr_code',
        'w-full max-h-[100px]': props.config.data.type === 'barcode',
    }))

    const textClass = computed(() => ({
        'text-left': hasRoles.value,
        'text-center w-full': !hasRoles.value,
    }))

    const userImageClass = computed(() => ({
        'w-[130px] h-[130px]': props.config.data.type === 'barcode',
        'w-[80px] h-[80px]': props.config.data.type === 'qr_code',
    }))

    onMounted(() => {
        if (!props.config.data.user_image_url) {
            return
        }
        moduleHelpers.resolvePotentialApiImageUrl(props.config.data.user_image_url)
            .then((url) => {
                loadedUserImage.value = url
            })
            .catch((error) => {
                console.log('Unable to load image', error)
            })
    })
</script>
