<template>
    <div class="border-t py-6 px-4 mt-8">
        <div class="prose prose-sm text-sm leading-5 font-medium max-w-full mb-1">
            Comments
        </div>
        <div
            v-if="config.data.send_comment_types.length"
            :id="commentModalUuid"
            class="border rounded-full mt-3 mb-4 py-2 px-3 text-sm text-gray-500 border-gray-300"
        >
            Write a comment...
        </div>
        <div v-for="(item, index) in config.items" class="my-3 flex items-start" :key="index">
            <UIAvatar :avatar="item.avatar" />
            <div>
                <UIHtml :html="item.avatar.description" class="prose prose-sm text-sm leading-5 font-medium max-w-full ml-2 mt-1 mb-1" style="overflow-wrap: anywhere;" />
                <UIHtml :html="item.body" class="prose prose-sm text-xs leading-[1.125rem] max-w-full ml-2 mb-1" style="overflow-wrap: anywhere;" />
                <UIHtml :html="item.subtitle" class="prose prose-sm text-xs leading-[1.125rem] max-w-full ml-2" style="overflow-wrap: anywhere;" />
                {{ item.avatar.avatar }}
            </div>
        </div>
    </div>
    <IonModal ref="modal" :trigger="commentModalUuid" :initial-breakpoint="0.01" :breakpoints="[0, 0.01, 0.5]" @did-present="onCommentModal" class="ds-modal-bottom-sheet">
        <div class="pt-6 h-[500px] flex flex-col">
            <IonContent ref="contentScroll" class="flex-1">
                <div class="flex items-center px-4  mb-4">
                    <div class="flex" v-if="config.data.send_comment_types.length > 1">
                        <div
                            v-for="(type, index) in config.data.send_comment_types"
                            class="px-4 py-1.5 text-sm font-medium"
                            :class="{
                                border: index !== selectedTypeIndex,
                                'border-2 border-[--color-600]': index === selectedTypeIndex,
                                'rounded-l-full': index === 0,
                                'rounded-r-full': index === config.data.send_comment_types.length - 1,
                            }"
                            :key="`tab-${index}`"
                            @click="switchType(index)"
                        >
                            {{ type.title }}
                        </div>
                    </div>
                    <DButton
                        class="ml-auto"
                        :theme="selectedType.button.type"
                        :left-icon="selectedType.button.icon"
                        @click="submitComment(selectedType.button)"
                        :loading="submitting"
                        :disabled="!comment"
                        icon-set="ph"
                    >
                        {{ selectedType.button.title }}
                    </DButton>
                </div>
                <div class="flex items-center px-4 mb-4" v-if="selectedType.avatar">
                    <UIAvatar class="mr-2" :avatar="assign(selectedType.avatar, { size: 'small' })" />
                    <div>
                        <div class="text-sm font-medium">{{ selectedType.avatar.description }}</div>
                        <div class="text-xs text-gray-700">{{ selectedType.avatar_subtext }}</div>
                    </div>
                </div>
                <DField id="comment" :form-errors="errors">
                    <DInput
                        v-model="comment"
                        tag="textarea"
                        placeholder="Write a comment..."
                        theme="transparent"
                        :rows="4"
                        ref="commentInput"
                    />
                </DField>
            </IonContent>
        </div>
    </IonModal>
</template>

<script setup>
    import { v4 as uuidv4 } from 'uuid'
    import { DInput, DField, DButton } from '@digistorm/spark'
    import UIAvatar from '@/components/ui/UIAvatar.vue'
    import UIHtml from '@/components/ui/UIHtml.vue'

    const props = defineProps({
        config: Object,
    })

    const router = useIonRouter()
    const moduleHelpers = useModuleHelpers()
    const { snackbar } = useSnackbars()

    const commentModalUuid = ref(uuidv4())
    const selectedTypeIndex = ref(0)
    const comment = ref('')
    const errors = ref({})
    const submitting = ref(false)
    const modal = ref()
    const commentInput = ref()

    const selectedType = computed(() => {
        return props.config.data.send_comment_types[selectedTypeIndex.value]
    })

    const switchType = (index) => {
        selectedTypeIndex.value = index
    }

    const onCommentModal = () => {
        commentInput.value.$refs.input.focus()
        setTimeout(() => {
            modal.value.$el.setCurrentBreakpoint(0.5)
        }, 100)
    }

    const submitComment = (button) => {
        button.link.payload = { comment: comment.value }

        submitting.value = true

        moduleHelpers.handleClick(button, null, false)
            .then((appModule) => {
                modal.value.$el.dismiss()
                comment.value = ''
                if (!appModule || !appModule.id) {
                    return
                }
                router.push({ name: 'view', params: { id: appModule.id } })
            })
            .catch((error) => {
                if (error?.response?.status === 422) {
                    errors.value = error.response.data?.errors
                    return
                }

                console.error('error', error)
                snackbar({
                    message: 'Error submitting form',
                    type: 'error',
                })
            })
            .finally(() => {
                submitting.value = false
            })
    }
</script>
