<template>
    <UISimple @click="toggleSwitch">
        <template v-slot:title>{{ props.config.data.title }}</template>
        <template v-slot:right-content>
            <DSwitch :model-value="switchValue" class="pointer-events-none" />
        </template>
    </UISimple>
</template>

<script setup>

    import { DSwitch } from '@digistorm/spark'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'

    const router = useIonRouter()
    const moduleHelpers = useModuleHelpers()
    const { snackbar } = useSnackbars()

    const props = defineProps({
        config: Object,
    })

    const errors = ref({})
    const submitting = ref(false)
    const switchValue = ref(props.config.data.value)

    const toggleSwitch = () => {
        switchValue.value = !switchValue.value
        const linkData = {
            link: {
                type: 'api-action',
                url: props.config.data.url,
                payload: {
                    value: switchValue.value,
                    name: props.config.data.name,
                },
            },
        }

        submitting.value = true

        return moduleHelpers.handleClick(linkData)
            .then((appModule) => {
                if (!appModule || !appModule.id) {
                    return
                }
                router.push({ name: 'view', params: { id: appModule.id } })
            })
            .catch((error) => {
                if (error?.response?.status === 422) {
                    errors.value = error.response.data
                    return
                }

                console.error('error', error)
                snackbar({
                    message: 'Error submitting form',
                    type: 'error',
                })
            })
            .finally(() => {
                submitting.value = false
            })
    }

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })
</script>
