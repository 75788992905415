<template>
    <UICardDetailText @click="onClick" :item="textItem" data-cy="card-detail-gallery">
        <template v-slot:indicators>
            <span class="text-gray-800 font-normal text-sm">{{ item.right_text }}</span>
        </template>

        <template v-slot:body>
            <div class="grid grid-cols-3 gap-[3px] mt-3">
                <div
                    v-for="(image, index) in item.thumbnail_urls"
                    :key="index"
                    class="w-full min-h-[130px] bg-cover bg-center bg-no-repeat"
                    :style="{ backgroundImage: `url(${image})` }"
                />
            </div>
        </template>
    </UICardDetailText>
</template>

<script setup>
    import UICardDetailText from '@/components/ui/CardDetailLayouts/UICardDetailText.vue'

    const props = defineProps({
        item: Object,
    })

    const moduleStore = useModuleStore()

    const moduleHelpers = useModuleHelpers()

    const clicking = ref(false)
    const textItem = ref({
        title: props.item.title,
        link: props.item.link,
    })

    const onClick = () => {
        if (clicking.value) {
            return
        }

        clicking.value = true
        moduleHelpers.handleClick(props.item)
            .catch((e) => {
                console.log('Failed to open link', e)
            })
            .finally(() => {
                clicking.value = false
            })
    }
</script>
