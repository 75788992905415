import { defineStore } from 'pinia'
import {
    difference,
    each,
    get,
    uniq,
    pull,
} from 'lodash'
import { api, mock } from '@/services'
import { events } from '@/composables/events'

export const useBadgeStore = defineStore('badge', {
    state: () => {
        return {
            badges: {},
            failedPermanently: [],
            failedAuth: [],
        }
    },
    getters: {
        getBadgeForUrl(state) {
            return (url) => get(state.badges, url)
        },
    },
    actions: {
        async fetch(url) {
            // Disable badge fetching when mocking
            if (mock.mocking() && !mock.mockingDashboard()) {
                return
            }

            if (this.failedPermanently.includes(url)) {
                return Promise.reject(new Error('Url does not support badges'))
            }

            // Remove the url from failed list
            pull(this.failedAuth, [url])

            return api.get(url)
                .then(({ data }) => {
                    // Set the badge
                    this.badges[url] = data
                })
                .catch((e) => {
                    const status = get(e, 'response.status')

                    // If endpoint doesn't support badges add to blacklist
                    if (status === 422) {
                        this.failedPermanently.push(url)
                    }

                    // If endpoint failed due to user being logged out
                    if (status === 403) {
                        this.failedAuth.push(url)
                    }

                    // Remove the badge
                    delete this.badges[url]
                })
        },
        async refreshAuthFailures() {
            const urls = difference(uniq(this.failedAuth), this.failedPermanently)

            // Clear out the urls failed due to auth
            this.failedAuth = []

            // Retry each url
            each(urls, (url) => {
                this.fetch(url)
                    .catch((e) => console.log(e))
            })
        },
        async refreshAll() {
            events.emit('refresh-badges')
        },
    },
})
