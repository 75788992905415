<template>
    <div class="my-6 px-4 flex flex-wrap gap-1">
        <DButton
            v-for="(button, index) in buttons"
            @click="handleButton(button)"
            :key="`button-${index}`"
            :theme="button.type"
            :wide="button.flex"
            :loading="submitting"
            :right-icon="button.icon"
            icon-set="ph"
        >
            {{ button.title }}
        </DButton>
    </div>
</template>

<script setup>
    import { DButton } from '@digistorm/spark'

    const moduleHelpers = useModuleHelpers()
    const { snackbar } = useSnackbars()

    const props = defineProps({
        config: Object,
    })

    const submitting = ref(false)
    const buttons = ref(props.config.data.buttons)

    const handleButton = (button) => {
        submitting.value = true

        return moduleHelpers.handleClick(button, null, false)
            .catch((e) => {
                console.log('Failed to open link', e)
            })
            .finally(() => {
                submitting.value = false
            })
    }

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })
</script>
