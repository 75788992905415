<template>
    <div>
        <div class="px-4 mb-4">
            <DField id="Search">
                <DInput
                    v-model="search"
                    left-icon="magnifying-glass"
                    placeholder="Search"
                    rounded
                />
            </DField>
        </div>
        <div class="flex mx-4 items-center justify-between">
            <div class="font-medium text-xl">
                {{ config.data.list_title }}
                <DBadge
                    v-if="config.data.badge"
                    class="mb-2 mr-1"
                    :color="config.data.badge.color"
                    :icon="config.data.badge.icon"
                    rounded
                >
                    {{config.data.badge.text}}
                </DBadge>
            </div>
            <DButton
                v-if="hasSelectableItems"
                theme="text-link"
                @click="toggleSelectAll"
            >
                {{ someSelected ? 'Deselect all' : 'Select all' }}
            </DButton>
        </div>
        <template
            v-for="(item, index) in items"
            :key="`item-${item.id}`"
        >
            <div v-if="isShown(item)" class="my-6 mx-4 flex items-center" data-cy="selection-list-item">
                <div v-if="item.disabled && item.disabled_icon" class="w-5 flex justify-center">
                    <PhIcon
                        color="#9FA6B2"
                        :icon="item.disabled_icon"
                        weight="fill"
                        :size="16"
                    />
                </div>
                <DCheckbox
                    v-else-if="!item.disabled"
                    class="scale-[1.3]"
                    v-model="selected[index]"
                />
                <div @click="handleItemClicked(index, item)" class="flex items-center w-full">
                    <div :class="{ 'ml-4': !item.disabled && !item.disabled_icon }">
                        <div class="text-neutral-base">
                            {{ item.title }}
                        </div>
                        <div class="text-neutral-empty text-sm">
                            {{ item.subtitle }}
                        </div>
                    </div>
                    <div class="ml-auto flex gap-2">
                        <UIIcon
                            v-for="icon in item.icons"
                            :key="`item-${item.id}-icon-${icon}`"
                            :icon="icon"
                            weight="bold"
                            :size="16"
                        />
                    </div>
                </div>
            </div>
        </template>
        <Teleport :to="footerMenu?.$el">
            <div v-if="someSelected" class="p-4 border-t flex bg-[var(--background)] w-full mt-auto gap-2">
                <DButton
                    icon-set="ph"
                    left-icon="note"
                    theme="secondary"
                    wide
                    @click="showBulkNoteModal"
                >
                    Add note
                </DButton>
                <DButton wide @click="showBulkActionModal">
                    Mark as...
                </DButton>
            </div>
            <div v-else-if="config.data.complete_url" class="p-4 border-t flex bg-[var(--background)] w-full mt-auto gap-2">
                <DButton wide @click="saveCompletion" :disabled="markingComplete">
                    Complete attendance
                </DButton>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
    import {
        DField, DInput, DButton, DCheckbox, DBadge,
    } from '@digistorm/spark'
    import { modalController } from '@ionic/vue'
    import { transform } from 'lodash'
    import PhIcon from '@/components/app/PhIcon.vue'
    import UIIcon from '@/components/ui/UIIcon.vue'
    import SelectionListActionModal
        from '@/components/ui/SelectionList/SelectionListActionModal.vue'
    import { events } from '@/composables'

    const moduleHelpers = useModuleHelpers()

    const props = defineProps({
        config: Object,
    })

    const footerMenu = inject('footerMenu')

    const search = ref('')
    const selected = ref([])
    const items = ref(props.config.items)
    const itemForReplacement = ref(null)
    const markingComplete = ref(false)
    const savingBulkNote = ref(false)

    watch(() => props.config.items, (newVal) => {
        items.value = newVal
    })

    const hasSelectableItems = computed(() => {
        return items.value.length && items.value.some((item) => !item.disabled)
    })

    const someSelected = computed(() => {
        return selected.value.length && selected.value.some((value) => value)
    })

    const selectedIds = computed(() => {
        return transform(selected.value, (result, isSelected, index) => {
            if (isSelected) {
                result.push(items.value[index].id)
            }
        }, [])
    })

    const isShown = (item) => {
        return !search.value || item.title.toLowerCase().includes(search.value.toLowerCase())
    }

    const deselectAll = () => {
        selected.value = items.value.map(() => false)
    }

    const toggleSelectAll = () => {
        selected.value = items.value.map((item) => !someSelected.value && !item.disabled)
    }

    const saveCompletion = () => {
        markingComplete.value = true
        events.emit('toggleLoadingOverlay', true)

        moduleHelpers.handleClick({
            link: {
                type: 'api-action',
                url: props.config.data.complete_url,
            },
        })
            .catch((e) => {
                console.log('Failed to save completion', e)
            })
            .finally(() => {
                events.emit('toggleLoadingOverlay', false)
                markingComplete.value = false
            })
    }

    const saveBulkAction = (action) => {
        events.emit('toggleLoadingOverlay', true)
        moduleHelpers.handleClick({
            link: {
                type: 'api-action',
                url: props.config.data.bulk_action_url,
                payload: {
                    ids: selectedIds.value,
                    action: action.value, // "value" of SelectionActionData
                },
            },
        })
            .catch((e) => {
                console.log('Failed to save bulk action', e)
            })
            .finally(() => {
                deselectAll()
                events.emit('toggleLoadingOverlay', false)
            })
    }

    const saveBulkNote = (note) => {
        if (savingBulkNote.value) {
            return
        }
        savingBulkNote.value = true
        events.emit('toggleLoadingOverlay', true)
        moduleHelpers.handleClick({
            link: {
                type: 'api-action',
                url: props.config.data.bulk_action_url,
                payload: {
                    ids: selectedIds.value,
                    note,
                },
            },
        })
            .catch((e) => {
                console.log('Failed to save bulk note', e)
            })
            .finally(() => {
                savingBulkNote.value = false
                deselectAll()
                events.emit('toggleLoadingOverlay', false)
            })
    }

    const saveItem = (index, item, action, note) => {
        events.emit('toggleLoadingOverlay', true)

        // Save index for replacement
        itemForReplacement.value = index

        moduleHelpers.handleClick({
            link: {
                type: 'api-action',
                url: item.action_url,
                payload: {
                    action: action?.value ?? item.status_value, // "value" of SelectionActionData
                    note: note ?? item.note,
                },
            },
        })
            .catch((e) => {
                console.log('Failed to save item', e)
            })
            .finally(() => {
                // Item should be replaced
                itemForReplacement.value = null
                events.emit('toggleLoadingOverlay', false)
            })
    }

    const createModal = (props, initialBreakpoint = 1, breakpoints = [0, 0.7, 1]) => {
        return modalController.create({
            component: SelectionListActionModal,
            initialBreakpoint,
            breakpoints,
            componentProps: props,
            handle: true,
            cssClass: 'ds-modal-bottom-sheet',
        })
    }

    const showActionModal = async (index, item) => {
        // Flag if these were modified in the modal
        let action = null
        let note = null
        let noteModified = false
        const modal = await createModal({
            title: props.config.data.actions_title,
            actions: props.config.data.actions,
            item,
            onAction: (newAction) => {
                action = newAction
                modal.dismiss()
            },
            onNote: (newNote) => {
                note = newNote
                noteModified = true
            },
            onEditingNote: (isEditingNote) => {
                modal.setCurrentBreakpoint(isEditingNote ? 0.7 : 1)
            },
        }, item.disabled ? 0.4 : undefined, item.disabled ? [0.4] : undefined)
        modal.onDidDismiss().then(() => {
            if (action || noteModified) {
                saveItem(index, item, action, note)
            }
        })
        modal.present()
    }

    const showBulkActionModal = async () => {
        const modal = await createModal({
            title: props.config.data.actions_title,
            actions: props.config.data.actions,
            onAction: (action) => {
                modal.dismiss()
                saveBulkAction(action)
            },
        })
        modal.present()
    }

    const showBulkNoteModal = async () => {
        const modal = await createModal({
            title: 'Add note',
            noteOnly: true,
            onNote: (note) => {
                modal.dismiss()
                saveBulkNote(note)
            },
        }, 0.7)
        modal.present()
    }

    const handleItemClicked = (index, item) => {
        showActionModal(index, item)
    }

    const handleReplaceItem = (item) => {
        if (itemForReplacement.value !== null) {
            items.value[itemForReplacement.value] = item
        }
    }

    onMounted(() => {
        events.on('replace-item', handleReplaceItem)
    })

    onUnmounted(() => {
        events.off('replace-item', handleReplaceItem)
    })

</script>
