<template>
    <div class="h-full overflow-y-auto">
        <div class="flex p-2">
            <DButton icon="chevron-left" theme="transparent" @click="prev" />
            <div class="grow flex items-center justify-center text-gray-800 font-medium">{{ text }}</div>
            <DButton icon="chevron-right" theme="transparent" @click="next" />
        </div>
        <div v-if="loading" class="flex items-center justify-center h-full -mt-16">
            <UILoading />
        </div>
        <ModuleComponents
            v-else-if="component"
            :modules="[component]"
        />
    </div>
</template>

<script setup>
    import { DButton } from '@digistorm/spark'
    import dayjs from 'dayjs'
    import ModuleComponents from '@/components/ModuleComponents.vue'

    const moduleStore = useModuleStore()

    const props = defineProps({
        config: Object,
    })

    const loading = ref(false)
    const component = ref(props.config.data.component)
    const date = ref(dayjs(props.config.data.date))
    const type = ref(props.config.data.type)

    const text = computed(() => {
        if (type.value === 'day') {
            return date.value.format('D MMMM YYYY')
        }
        const startOfWeek = date.value.startOf('week')
        const endOfWeek = date.value.endOf('week')
        let text = startOfWeek.format('D')
        if (!startOfWeek.isSame(endOfWeek, 'month')) {
            text += `${startOfWeek.format(' MMM')} - ${endOfWeek.format('D MMM')}`
        } else {
            text += ` - ${endOfWeek.format('D MMMM')}`
        }
        if (!dayjs().isSame(endOfWeek, 'year')) {
            text += endOfWeek.format(' YYYY')
        }
        return text
    })

    watch(date, (value) => {
        loading.value = true
        moduleStore.loadContent(props.config.data.url, {
            date: value.format('YYYY-MM-DD'),
        })
            .then(({ data }) => {
                component.value = data
                loading.value = false
            })
    })
    const prev = () => {
        if (type.value === 'day') {
            date.value = date.value.subtract(1, 'day')
            return
        }
        date.value = date.value.subtract(1, 'week')
    }
    const next = () => {
        if (type.value === 'day') {
            date.value = date.value.add(1, 'day')
            return
        }
        date.value = date.value.add(1, 'week')
    }
</script>
