<template>
    <div class="flex flex-col items-start pt-10 px-8 w-full min-h-full flex-grow">
        <div class="w-full">
            <div class="mb-10 w-full">
                <h2 class="text-3xl font-bold mb-4">{{ config.layout.title }}</h2>
                <p class="text-gray-600">{{ description }}</p>
            </div>
            <div class="w-full flex justify-center items-center flex-grow">
                <PhIcon weight="thin" :icon="icon" icon-set="phosphor" :size="188" :color="primaryColor" />
            </div>
        </div>
        <Teleport :to="footerMenu?.$el">
            <div class="px-8 pb-6 flex flex-col bg-[var(--background)] w-full mt-auto" data-cy="tour-footer">
                <DButton class="mb-1" @click="onBiometricAction" wide>
                    {{ config.action_button_text }}
                </DButton>
                <DButton class="mb-1" @click="onSkipAction" theme="transparent" wide>
                    {{ config.skip_step_button_text }}
                </DButton>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
    import { DButton } from '@digistorm/spark'
    import { biometric } from '@/services/biometric'
    import PhIcon from '@/components/app/PhIcon.vue'
    import { useTourStore } from '@/stores/tour'

    const emit = defineEmits(['action-skipped', 'action-completed'])

    const versionStore = useVersionStore()
    const { snackbar } = useSnackbars()
    const tourStore = useTourStore()

    const { version } = storeToRefs(versionStore)

    const props = defineProps({
        config: Object,
    })

    const footerMenu = inject('footerMenu', null)
    const biometryType = ref(null)

    const primaryColor = computed(() => get(version.value, 'primary_color'))

    const description = computed(() => {
        return props.config.layout.description.replace('{biometryType}', biometryType.value)
    })

    const icon = computed(() => {
        switch (biometryType.value) {
            case 'Biometric Login':
            case 'Iris Authentication':
            case 'Face Authentication':
            case 'Face ID':
                return 'scan-smiley'
            case 'Fingerprint Authentication':
            case 'Touch ID':
                return 'fingerprint'
            default:
                return 'scan-smiley'
        }
    })

    const onBiometricAction = async () => {
        tourStore.handleAction(props.config.action_type, false)
        await biometric.authenticate().then(() => {
            biometric.setEnabled(true)
        }).catch(async () => {
            await biometric.setEnabled(false)
            snackbar({
                message: `Failed to enable ${await biometric.biometryType()}`,
                type: 'error',
            })
        })
        emit('action-completed', true)
    }

    const onSkipAction = () => {
        tourStore.handleAction(props.config.action_type, true)
        emit('action-skipped')
    }

    onBeforeMount(async () => {
        biometryType.value = await biometric.biometryType()
    })
</script>
